.signup-container {
    max-width: 600px;
    margin: 6rem auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.signup-container h2 {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 2rem;
    font-weight: bold;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.form-group label {
    width: 10rem;
    margin-right: 10px;
    font-weight: bold;
}

.form-group input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.input-button-group {
    display: flex;
    align-items: center;
    margin-left: 3rem;
    width: 100%;
}

.input-button-group input {
    flex: 1;
}

.verification-button,
.address-search-button {
    padding: 10px 0.1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.verification-button {
  width: 8rem;
  margin-left: 10px;
}

.address-search-button {
    width: 8rem;
    margin-left: 10px;
}

.verification-button:hover,
.address-search-button:hover {
    background-color: #0056b3;
}

.signup-button {
    width: 100%;
    padding: 15px;
    background-color: #207a35;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
}

.signup-button:hover {
    background-color: #218838;
}

.form-group label input[type="radio"] {
    margin-right: 10px;
}

.marketing-consent {
    display: flex;
    gap: 10px;
}

.form-group2 {
  display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.form-group2 span {
  width: 10rem;
  margin-right: 10px;
  font-weight: bold;
}

.form-group2 span input {
  width: 1005;
  margin-right: 10px;
  font-weight: bold;
}

.custom-radio-group {
  display: flex;
  gap: 20px;
}

.custom-radio-input {
  display: none;
}

.custom-radio-label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
}

.custom-radio-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 2px solid #007bff;
  border-radius: 50%;
  background-color: white;
}

.custom-radio-input:checked + .custom-radio-label::before {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-radio-label.active {
  color: #007bff;
}

@media (max-width: 600px) {
    .form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-group label {
        width: 100%;
        margin-bottom: 5px;
    }

    .form-group input {
        width: 100%;
    }

    .input-button-group {
        flex-direction: row;
        align-items: flex-start;
        margin-left: 0px;
    }

    .verification-button {
        width: 6rem;
        margin-left: 0.5rem;
    }

    .address-search-button {
        width: 6rem;
        margin-left: 0.5rem;
    }

    .term-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .lblterm {
        width: 100%;
        margin-bottom: 5px;
    }

    .divadvise{
        margin-left: 3rem;
    }

    .spanover {
        display: flex;
        font-size: 1.1rem;
    }

    .form-group2 label {
        width: 6rem;
        margin-right: 10px;
        font-weight: bold;
    }
}

.marketing-consent {
  display: flex;
  gap: 10px;
}

.terms-note {
  font-size: 0.9rem;
  color: #6c757d;
  margin-top: 10px;
}

.form-check-input {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25em;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    position: relative;
  }
  
  .form-check-input:checked {
    background-color: #142d9b;
    border-color: #142d9b;
  }
  
  .form-check-input:checked::before {
    content: '\2713';
    color: #fff;
    font-size: 1.2em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .divdorgap{
    margin : 4rem 0;
  }

  .term-group {
    display: flex;
    margin-bottom: 15px;
}

.lblterm {
    width: 10rem;
    margin-right: 10px;
    font-weight: bold;
}

.allAgree {
    flex: 1;
    font-size: 1rem;
}

.allAgree h3 {
    display: flex;
    align-items: center;
}

.allAgree h3 input[type="checkbox"] {
    margin-bottom: 0.4rem;
}


.lblover {
    margin-top: 0.3rem;
    width: 16rem !important;
}

.lbldetail {
    margin-top: 0.3rem;
    font-weight: normal !important;
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
}

.lbldetail:hover {
    color: #0056b3;
    text-decoration: none;
  }

.modal-body pre {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    text-align: left; /* 왼쪽 정렬 추가 */
    white-space: pre-wrap; /* 줄바꿈과 공백을 유지하여 긴 문자열이 잘리지 않도록 설정 */
    word-wrap: break-word; /* 긴 단어가 잘리지 않고 줄바꿈되도록 설정 */
    overflow-wrap: break-word; /* 긴 단어가 잘리지 않고 줄바꿈되도록 설정 */
}

@media (min-width: 600px) {
    .spanover {
        display: flex;
        font-size: 1.0rem;
    }

    .form-group2 label {
        width: 10rem;
        margin-right: 10px;
        font-weight: bold;
    }
}

/* 세련된 라디오 버튼 스타일 */
.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-label {
  position: relative;
  padding-left: 35px;
  margin-right: 20px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
}

.radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.radio-label:hover input ~ .radio-checkmark {
  background-color: #ccc;
}

.radio-label input:checked ~ .radio-checkmark {
  background-color: #2196F3;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-label input:checked ~ .radio-checkmark:after {
  display: block;
}

.radio-label .radio-checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.signup-success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  text-align: center;
  z-index: 1000;
}

.signup-success-modal h2 {
  color: #4CAF50;
  margin-bottom: 20px;
  font-size: 24px;
}

.signup-success-modal p {
  color: #333;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
}

.signup-success-modal button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-success-modal button:hover {
  background-color: #45a049;
}

.signup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.signup-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 100%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #aaa;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-close:hover {
  color: #333;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.signup-success-modal {
  animation: fadeIn 0.3s ease-out;
}

/* 기존 스타일 유지 */

.modern-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modern-modal {
  background: white;
  border-radius: 20px;
  padding: 0;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.modern-modal-content {
  display: flex;
  flex-direction: column;
  top: 6rem !important;
  max-height: 30rem;
}

.modern-modal-header {
  background-color: #f8f9fa;
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modern-modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #343a40;
}

.modern-close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.modern-close-button svg {
  width: 24px;
  height: 24px;
  fill: #6c757d;
  transition: fill 0.2s;
}

.modern-close-button:hover svg {
  fill: #343a40;
}

.modern-modal-body {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.modern-modal-body pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #212529;
}

.modern-modal-footer {
  padding: 20px;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: flex-end;
}

.modern-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.modern-button:hover {
  background-color: #0056b3;
}

.success-modal .modern-modal-header {
  background-color: #3f3ce9;
}

.success-modal .modern-modal-header h2 {
  color: white;
}

.success-modal .modern-close-button svg {
  fill: white;
}

.success-modal .modern-button {
  background-color: #3f3ce9;
}

.success-modal .modern-button:hover {
  background-color: #3f3ce9;
}

@media (max-width: 576px) {
  .modern-modal {
    width: 95%;
  }

  .modern-modal-header h2 {
    font-size: 1.2rem;
  }

  .modern-modal-body {
    padding: 15px;
  }

  .modern-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #e7edff;
  margin: 1em;
}

.rdp-root {
  margin: 0 auto;
}

/* 기존 스타일 유지 */

.form-group .input-button-group:not(:first-child) {
  margin-top: 10px;
}

.form-group .input-button-group input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.form-group .input-button-group .verification-button {
  width: 8rem;
  margin-left: 10px;
  padding: 10px 0.1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.form-group .input-button-group .verification-button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .form-group .input-button-group {
    flex-direction: column;
    align-items: stretch;
  }

  .form-group .input-button-group input {
    width: 100%;
    margin-bottom: 10px;
  }

  .form-group .input-button-group .verification-button {
    width: 100%;
    margin-left: 0;
  }
}
