/* 세련된 디자인을 위한 공통 스타일 */
.schedule-container {
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.schedule-list {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr; /* 기본적으로 1열 */
}

.schedule-item {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; /* 넓이를 더 넓게 */
    height: auto; /* 높이를 줄임 */
    min-height: 50px; /* 최소 높이 설정 */
}

.schedule-item:hover {
    transform: translateY(-5px) scale(1.01);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.schedule-item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule-item h4 {
    font-size: 1.2rem; /* 글자 크기 증가 */
    font-weight: 700;
    color: #1a202c;
    margin: 0;
    letter-spacing: -0.5px;
}

.schedule-item .date-time {
    font-size: 1.1rem; /* 글자 크기 증가 */
    color: #4a5568;
    font-weight: 500;
    margin: 0;
}

.schedule-item .instructor {
    font-size: 1.1rem; /* 글자 크기 증가 */
    font-weight: 700;
    color: #0776df;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.schedule-details {
    font-size: 1rem; /* 글자 크기 증가 */
    transition: all 0.3s ease;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    background: none; /* 배경색 제거 */
}

.schedule-details p {
    margin: 0;
    color: #2d3748;
}

@media (min-width: 449px) {
    .schedule-list {
        grid-template-columns: repeat(2, 1fr); /* 2열 */
    }
}

@media (max-width: 448px) {
    .schedule-list {
        grid-template-columns: 1fr; /* 1열 */
    }

    .schedule-item {
        padding: 10px;
    }

    .schedule-item h4 {
        font-size: 1.1rem; /* 글자 크기 증가 */
    }

    .schedule-item .date-time,
    .schedule-item .instructor {
        font-size: 1rem; /* 글자 크기 증가 */
    }

    .schedule-details {
        font-size: 0.95rem; /* 글자 크기 증가 */
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.popup-content button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.popup-content button:hover {
    background-color: #0056b3;
}