.payment-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  min-height: 100vh;
  background-color: #f7f9fc;
  padding: 2rem;
}

.result-card {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.result-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.result-icon.success {
  color: #4CAF50;
}

.result-icon.failure {
  color: #F44336;
}

.result-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.result-message {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.home-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
